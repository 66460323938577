import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { Hero } from "@components/Hero"
import { HeroImage, Intro, Category, MobileDesign, Pagination, Section } from "@components/Work"
import { Carousel } from "@components/Carousel"
import { CursorMarker } from "@components/CursorMarker"
import { Arrow } from "@components/UI"
import { splitText } from "@utils"
import LogoOne from "@svg/work/ans/logo-01.svg"
import LogoTwo from "@svg/work/ans/logo-02.svg"
import LogoThree from "@svg/work/ans/logo-03.svg"
import LogoFour from "@svg/work/ans/logo-04.svg"
import NegativeSixteenPercent from "@svg/work/ans/negative-16-percent.svg"
import PlusFivePercent from "@svg/work/ans/plus-5-percent.svg"
import PlusFortyOnePercent from "@svg/work/ans/plus-41-percent.svg"
import FontsDesktop from "@svg/work/ans/fonts-desktop.svg"
import FontsTablet from "@svg/work/ans/fonts-tablet.svg"
import FontsMobile from "@svg/work/ans/fonts-mobile.svg"
import workData from "@json/work.json"

const ANSPage = ({ data }) => {
  const {
    webpageOneImage,
    webpageTwoImage,
    mobileOneImage,
    mobileTwoImage,
    mobileThreeImage,
    printOneDesktopImage,
    printOneTabletImage,
    printTwoDesktopImage,
    printTwoTabletImage,
    printThreeDesktopImage,
    printThreeTabletImage,
    siteHomeImage,
  } = data
  const { name, headline, theme, url } = workData["ans"]
  const { themeColor } = usePageSetup({
    themeColor: theme.background,
    navigationColor: theme.navigationColor,
    displayGeneralForm: false,
  })

  return (
    <Layout>
      <Seo title={name} description={headline} />
      {/* HERO */}
      <section className={themeColor}>
        <Hero className="pb-clamp-36-54 pt-clamp-16-26">
          <Hero.Title>
            <h1 className="tracking-normal text-white text-18">{name}</h1>
            <h2
              data-aos="stagger"
              className="text-headline mt-clamp-6-8"
              dangerouslySetInnerHTML={{
                __html: splitText({
                  "text-white": headline,
                }),
              }}
            />
          </Hero.Title>
        </Hero>
      </section>

      <Arrow colorTop="bg-white" colorBottom="bg-white" fillColor="text-white" />

      <main className="overflow-hidden">
        <HeroImage client="ans" />
        <Category>B2B branding</Category>
        <Intro>
          <Intro.Overview>
            <Intro.Title>Telling a standout story.</Intro.Title>
            <div className="mt-10">
              <p>
                ANS Solutions is a leader in workers’ compensation cost containment—a niche market dominated by stodgy
                stories from big corporations, “medical legalese,” and generic imagery.
              </p>
              <p>
                With a unique approach to their work and a reputation for excellence, ANS had an original and compelling
                story to tell, but they struggled to find their voice among louder, larger competitors.
              </p>
              <p>
                That’s where JK came in. We set out to build a powerful new B2B brand and website that would stand out
                in a sea of sameness—attracting new leads and engaging current clients more deeply.
              </p>
            </div>
          </Intro.Overview>
          <Intro.Deliverables url={url}>
            <li>Branding</li>
            <li>Messaging</li>
            <li>Visual identity</li>
            <li>Research</li>
            <li>Persona development</li>
            <li>Logo architecture</li>
            <li>Tagline</li>
            <li>Copywriting</li>
            <li>Sales &amp; marketing collateral</li>
            <li>Brand guidelines</li>
            <li>UI/UX design</li>
            <li>Copywriting</li>
            <li>Website development</li>
          </Intro.Deliverables>
        </Intro>

        <section className="mt-clamp-34-29">
          <Section className="col-span-10">
            <div className="grid-cols-10 xl:grid">
              <div className="col-span-7">
                <Section.Title>Uncovering the details that make the difference.</Section.Title>
              </div>
            </div>
            <div className="grid-cols-10 xl:grid">
              <div className="col-span-9">
                <Section.Description>
                  <p>JK’s branding process provided a clear roadmap for creating a distinctive B2B identity for ANS.</p>
                  <p>
                    We started with audience and competitive research, which provided a real-world view of their
                    strengths and challenges—and where they had an opportunity to tell a story that quickly communicates
                    what makes ANS a uniquely capable partner.
                  </p>
                  <p>
                    These insights set the stage for a daylong interactive branding session, where we worked with the
                    ANS team to pull out and put together the pieces of a compelling new brand identity.
                  </p>
                </Section.Description>
              </div>
            </div>
          </Section>
          <div className="mt-clamp-17-22">
            <div className="container relative flex">
              <CursorMarker>
                <CursorMarker.Gallery />
              </CursorMarker>
              <div className="w-full sm:w-5/6 lg:w-1/2">
                <Carousel>
                  <Carousel.Swiper spaceBetween={16} centeredSlides={false}>
                    <Carousel.Slide>
                      <div data-aos="fade-up" className="w-full pointer-events-none">
                        <LogoOne />
                      </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                      <div data-aos="fade-up" data-aos-delay="200" className="w-full pointer-events-none">
                        <LogoTwo />
                      </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                      <div data-aos="fade-up" data-aos-delay="400" className="w-full pointer-events-none">
                        <LogoThree />
                      </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                      <div data-aos="fade-up" data-aos-delay="600" className="w-full pointer-events-none">
                        <LogoFour />
                      </div>
                    </Carousel.Slide>
                  </Carousel.Swiper>
                </Carousel>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-clamp-25-41">
          <Section className="col-span-9">
            <Section.Description>
              <p>
                Together, we uncovered the details that would help them make personal connections in an often impersonal
                space: Face-to-face collaboration, commitment to service, and a genuine drive to create better outcomes
                for everyone—from the patients in need of quality care to the insurance adjusters who help make that
                possible.
              </p>
              <p>
                These differentiators became the drivers of a new brand identity rooted in an emotional, human-centric
                story and culminating in a new tagline: “Pioneering New Possibilities.”
              </p>
              <p>
                It’s an inspired position that allows ANS to tell a meaningful story today, while being flexible enough
                to adapt as their business evolves.
              </p>
            </Section.Description>
          </Section>

          <div className="container flex justify-center pt-clamp-26-35">
            <div className="w-full xl:w-10/12">
              <div className="w-full">
                <h3 data-aos="fade-up" className="text-18">
                  Colors
                </h3>
              </div>
              <div data-aos="fade-up" className="mt-16">
                <div className="w-full pb-5 pl-5 text-white pt-39" style={{ background: "#012340" }}>
                  <span className="font-bold text-18">#012340</span>
                </div>
                <div className="grid w-full grid-cols-1 text-white sm:grid-cols-3 lg:grid-cols-6">
                  <div className="pb-5 pl-5 pt-15" style={{ background: "#1EB9E0" }}>
                    <span className="font-bold text-18">#1EB9E0</span>
                  </div>
                  <div className="pb-5 pl-5 pt-15" style={{ background: "#1380C8" }}>
                    <span className="font-bold text-18">#1380C8</span>
                  </div>
                  <div className="pb-5 pl-5 pt-15" style={{ background: "#474493" }}>
                    <span className="font-bold text-18">#474493</span>
                  </div>
                  <div className="pb-5 pl-5 pt-15" style={{ background: "#DDE4E8" }}>
                    <span className="font-bold text-18">#DDE4E8</span>
                  </div>
                  <div className="pb-5 pl-5 pt-15" style={{ background: "#5C6269" }}>
                    <span className="font-bold text-18">#5C6269</span>
                  </div>
                  <div className="pb-5 pl-5 pt-15" style={{ background: "#1C1F21" }}>
                    <span className="font-bold text-18">#1C1F21</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-clamp-34-42">
          <div className="container flex justify-center">
            <div className="w-full xl:w-10/12">
              <div className="w-full">
                <h3 data-aos="fade-up" className="text-18">
                  Brand typography
                </h3>
              </div>
              <div data-aos="fade-up" className="mt-6">
                <div className="display-desktop">
                  <FontsDesktop />
                </div>
                <div className="display-tablet">
                  <FontsTablet />
                </div>
                <div className="display-mobile">
                  <FontsMobile />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-clamp-40-77">
          <Section className="col-span-9">
            <Section.Title>Making a bold introduction.</Section.Title>
            <Section.Description>
              <p>
                With the brand built and the website ready, it was time for ANS to reveal their new brand to their
                clients and prospects.
              </p>
              <p>
                We helped ANS officially introduce their new brand at the industry’s largest trade show, creating new
                booth graphics, sell sheets, and an email invitation to a special meet-and-greet event.
              </p>
              <p>
                Beyond the show, we also developed a communications roadmap with clear talking points that ensured ANS’s
                people are all telling the same consistent story across every client touchpoint.
              </p>
            </Section.Description>
          </Section>
          <div className="container relative mt-clamp-18-25">
            <Carousel>
              <CursorMarker>
                <CursorMarker.Print />
              </CursorMarker>
              <Carousel.Swiper
                breakpoints={{
                  1436: {
                    spaceBetween: 80,
                    slidesPerView: "auto",
                  },
                }}
              >
                <Carousel.Slide>
                  <div data-aos="fade-up">
                    <Carousel.Print>
                      <div className="display-mobile-tablet">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printOneTabletImage)}
                          alt="Print 1"
                        />
                      </div>
                      <div className="display-desktop">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printOneDesktopImage)}
                          alt="Print 1"
                        />
                      </div>
                    </Carousel.Print>
                  </div>
                </Carousel.Slide>
                <Carousel.Slide>
                  <div data-aos="fade-up" data-aos-delay="200">
                    <Carousel.Print>
                      <div className="display-mobile-tablet">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printTwoTabletImage)}
                          alt="Print 2"
                        />
                      </div>
                      <div className="display-desktop">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printTwoDesktopImage)}
                          alt="Print 2"
                        />
                      </div>
                    </Carousel.Print>
                  </div>
                </Carousel.Slide>
                <Carousel.Slide>
                  <div data-aos="fade-up" data-aos-delay="400">
                    <Carousel.Print>
                      <div className="display-mobile-tablet">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printThreeTabletImage)}
                          alt="Print 3"
                        />
                      </div>
                      <div className="display-desktop">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printThreeDesktopImage)}
                          alt="Print 3"
                        />
                      </div>
                    </Carousel.Print>
                  </div>
                </Carousel.Slide>
              </Carousel.Swiper>
            </Carousel>
          </div>
        </section>

        <section className="pt-clamp-38-71">
          <Section className="col-span-8 xl:col-span-6">
            <Section.Title>Uniting functionality with feeling.</Section.Title>
            <Section.Description>
              <p>
                With the branding complete, we turned our attention to building a completely new website for ANS, one
                that showcases their new story and visual identity, and makes a complex depth of information easy to
                find and consume.
              </p>
              <p>
                A new dropdown menu system allows users to locate what they’re looking for more quickly, and a
                thoughtful content hierarchy allows users to scan each page without losing any substance, all while
                branded copy weaves ANS’s new messaging throughout the entire site.
              </p>
            </Section.Description>
          </Section>

          <div className="bg-work-ans-light mt-clamp-22-29">
            <div className="container relative flex justify-center">
              <div className="absolute inset-0">
                <div className="w-full aspect-w-1436 aspect-h-243 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
              </div>
              <div className="flex flex-col w-full lg:flex-row md:w-10/12 gap-x-4">
                <div className="flex w-full lg:block lg:w-1/2">
                  <div className="w-full sm:w-5/6 lg:w-full">
                    <div data-aos="fade-up" className="overflow-hidden rounded shadow-xl">
                      <GatsbyImage image={getImage(webpageOneImage)} alt="AD webpage - 1" />
                    </div>
                  </div>
                </div>
                <div className="flex w-full lg:block lg:w-1/2 pt-clamp-14-37">
                  <div className="w-full ml-auto sm:w-5/6 lg:w-full">
                    <div data-aos="fade-up" className="overflow-hidden rounded shadow-xl">
                      <GatsbyImage image={getImage(webpageTwoImage)} alt="AD webpage - 2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-clamp-28-50">
              <Section className="col-span-10 xl:col-span-7">
                <Section.Description>
                  <p>
                    And new lead-gen functionality—a streamlined contact form, records upload page, and simplified
                    referral process—was designed to generate more leads and increase engagement amongst current
                    clients.
                  </p>
                </Section.Description>
              </Section>
            </div>
            <div className="relative mt-clamp-15-30">
              <div className="absolute inset-0 flex">
                <div className="w-full mt-auto aspect-w-326 aspect-h-170 sm:aspect-w-1436 sm:aspect-h-270 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
              </div>
              <MobileDesign>
                <MobileDesign.Phone className="phone-1">
                  <GatsbyImage
                    className="overflow-hidden shadow rounded-3xl"
                    image={getImage(mobileOneImage)}
                    alt="AD mobile - 1"
                  />
                </MobileDesign.Phone>
                <MobileDesign.Phone className="phone-2 sm:pt-clamp-12-14">
                  <GatsbyImage
                    className="overflow-hidden shadow rounded-3xl"
                    image={getImage(mobileTwoImage)}
                    alt="AD mobile - 2"
                  />
                </MobileDesign.Phone>
                <MobileDesign.Phone className="phone-3 sm:pt-clamp-24-44">
                  <GatsbyImage
                    className="overflow-hidden shadow rounded-3xl"
                    image={getImage(mobileThreeImage)}
                    alt="AD mobile - 3"
                  />
                </MobileDesign.Phone>
              </MobileDesign>
            </div>
          </div>
        </section>

        <section className="pt-clamp-37-70">
          <div className="container">
            <div className="flex flex-col-reverse grid-cols-12 lg:grid">
              <div className="col-start-1 col-end-7 mt-clamp-22-16 lg:mt-0">
                <div data-aos="fade-up" className="grid gap-y-15 md:grid-cols-3 gap-x-4">
                  <div className="w-3/5 mx-auto md:w-full">
                    <NegativeSixteenPercent />
                    <h4 className="mt-6 text-center font-semi text-clamp-16-18">Overall bounce rate</h4>
                  </div>
                  <div className="w-3/5 mx-auto md:w-full">
                    <PlusFortyOnePercent />
                    <h4 className="mt-6 text-center font-semi text-clamp-16-18">Page views</h4>
                  </div>
                  <div className="w-3/5 mx-auto md:w-full">
                    <PlusFivePercent />
                    <h4 className="mt-6 text-center font-semi text-clamp-16-18">Page views per session</h4>
                  </div>
                </div>
              </div>
              <div className="col-start-8 col-end-13 mx-auto sm:w-5/6 lg:w-full">
                <Section.Title>Optimizing the possibilities.</Section.Title>
                <Section.Description>
                  <p>
                    To ensure that the new website would keep delivering the results ANS needed, we provided
                    recommendations to optimize their content strategy to drive more traffic through organic search.
                  </p>
                  <p>Their post-launch website analytics make this a true success story.</p>
                </Section.Description>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-clamp-37-70">
          <div className="container flex justify-center">
            <div className="grid-cols-12 lg:grid gap-x-4">
              <div className="col-span-5 mx-auto sm:w-5/6 lg:w-full">
                <Section.Title>A partnership full of potential.</Section.Title>
                <Section.Description>
                  <p>
                    Our partnership with ANS set them up for long-term success—a powerful and authentic new brand, a
                    smart new website, and a rollout strategy and support that told the right story to the right
                    audiences at the right time. We’re excited to continue to help them pioneer new possibilities well
                    into the future.
                  </p>
                </Section.Description>
              </div>
              <div className="col-span-7 mt-16 lg:mt-0 lg:grid grid-cols-14">
                <div className="col-start-2 col-end-15">
                  <GatsbyImage
                    className="overflow-hidden shadow rounded-2xl"
                    image={getImage(siteHomeImage)}
                    alt="ANS Home Page"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-clamp-35-62 pb-clamp-27-43">
          <div className="container flex justify-center">
            <div data-aos="fade-up" className="w-full xl:w-10/12">
              <Carousel.Quote client="ans" single={true}></Carousel.Quote>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-gray-darkest" colorBottom="bg-white" fillColor="text-white" />
        <Pagination client="ans" />
      </main>
    </Layout>
  )
}

export default ANSPage

export const ansQuery = graphql`
  query ANSPageQuery {
    webpageOneImage: file(relativePath: { eq: "work/ans/webpage-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    webpageTwoImage: file(relativePath: { eq: "work/ans/webpage-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    mobileOneImage: file(relativePath: { eq: "work/ans/mobile-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    mobileTwoImage: file(relativePath: { eq: "work/ans/mobile-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    mobileThreeImage: file(relativePath: { eq: "work/ans/mobile-03.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printOneDesktopImage: file(relativePath: { eq: "work/ans/print-01-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printOneTabletImage: file(relativePath: { eq: "work/ans/print-01-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printTwoDesktopImage: file(relativePath: { eq: "work/ans/print-02-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printTwoTabletImage: file(relativePath: { eq: "work/ans/print-02-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printThreeDesktopImage: file(relativePath: { eq: "work/ans/print-03-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printThreeTabletImage: file(relativePath: { eq: "work/ans/print-03-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    siteHomeImage: file(relativePath: { eq: "work/ans/site-home.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
